body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  background-color: #484454;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #cccccc;
  margin-top: 20px;
  margin-bottom: 20px;
}

.app-container {
  background-color: #444654;
  height: 100vh; /* Adjust the height as needed */
}

.social-links {
  margin-top: 30px;
  text-align: center;
}

.social-links a {
  display: inline-block;
  margin: 0 10px;
  color: #777;
  font-size: 20px;
}

.social-links a:hover {
  color: #333;
}